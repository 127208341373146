import UseAxios from '@/composables/types/useAxios'
import { UseAxiosPaginated } from '@/composables/types/useAxiosPaginated'
import { BasicEntity, MetaProperties } from '@/api/types/misc'
import { Hateoas } from '@/api/types/hateoas'

enum PROFILE_TYPE {
  'TENDERING' = 'TENDERING',
  'ASSIGNMENT' = 'ASSIGNMENT',
}

interface ProfileOutput extends MetaProperties, ProfileAugmentations, Hateoas {
  nr: string | null
  name: string
  description: string
  type: keyof typeof PROFILE_TYPE
}

interface ProfileAugmentations {
  tenderings: BasicEntity[]
  assignments: BasicEntity[]
}

type ProfileInput = Omit<ProfileOutput, keyof MetaProperties | keyof ProfileAugmentations | keyof Hateoas>

type ProfileId = ProfileOutput['id']

type UseGetProfiles = UseAxiosPaginated<ProfileOutput>
type UseGetProfilesBasic = UseAxios<BasicEntity[]>
type UseCreateProfile = { createProfile: (data: ProfileInput) => Promise<ProfileOutput> } & UseAxios<ProfileOutput>
type UseUpdateProfile = {
  updateProfile: (id: ProfileId, data: ProfileOutput) => Promise<ProfileOutput>
} & UseAxios<ProfileOutput>
type UseDeleteProfile = {
  deleteProfile: (id: ProfileId) => void
} & UseAxios<void>

export {
  ProfileOutput,
  ProfileInput,
  ProfileId,
  UseGetProfiles,
  UseGetProfilesBasic,
  UseCreateProfile,
  UseUpdateProfile,
  UseDeleteProfile,
  PROFILE_TYPE,
}
