import { useAxiosPaginated } from '@/composables/useAxiosPaginated'
import { useAxios } from '@/composables/useAxios'

import {
  PersonOutput,
  PersonInput,
  PersonId,
  UseGetPersons,
  UseGetPersonsBasic,
  UseGetPerson,
  UseGetPersonBudget,
  UseCreatePerson,
  UseUpdatePerson,
  UseDeletePerson,
  UseExportPersons,
  UseGetPersonsDashboard,
  UseGetPersonsDashboardLeasing,
} from '@/api/types/person'
import { OptionalFiltersParam } from '@/composables/types/useFilter'
import { PromiseType } from '@/utils/types/PromiseType'
import { BasicEntity } from '@/api/types/misc'

const VERSION = 'v1'
const RESOURCE = 'persons'
const RETIREMENTS = 'dashboard/retirements'
const ENTRIES = 'dashboard/entries'
const LEASING = 'dashboard/leasing'

function useGetPersons(): UseGetPersons {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetPersons['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
  })
  return axios
}

function useGetPersonsDashboardRetirements(): UseGetPersonsDashboard {
  const axios = useAxios<PromiseType<ReturnType<UseGetPersonsDashboard['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}/${RETIREMENTS}`,
  })
  return axios
}

function useGetPersonsDashboardEntries(): UseGetPersonsDashboard {
  const axios = useAxios<PromiseType<ReturnType<UseGetPersonsDashboard['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}/${ENTRIES}`,
  })
  return axios
}

function useGetPersonsDashboardLeasing(): UseGetPersonsDashboardLeasing {
  const axios = useAxios<PromiseType<ReturnType<UseGetPersonsDashboardLeasing['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}/${LEASING}`,
  })
  return axios
}

function useGetPersonsBasic(): UseGetPersonsBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useCreatePerson(): UseCreatePerson {
  const axios = useAxios<PromiseType<ReturnType<UseCreatePerson['createPerson']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createPerson(data: PersonInput): Promise<PersonOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createPerson,
  }
}

function useGetPerson(): UseGetPerson {
  const axios = useAxios<PromiseType<ReturnType<UseGetPerson['getPerson']>>>({ method: 'GET' })

  function getPerson(id: PersonId): Promise<PersonOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    getPerson,
  }
}

function useGetPersonBudget(): UseGetPersonBudget {
  const axios = useAxios<PromiseType<ReturnType<UseGetPersonBudget['getPersonBudget']>>>({ method: 'GET' })

  function getPersonBudget(id: PersonId): Promise<PersonOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}/budget` })
  }

  return {
    ...axios,
    getPersonBudget,
  }
}

function useUpdatePerson(): UseUpdatePerson {
  const axios = useAxios<PromiseType<ReturnType<UseUpdatePerson['updatePerson']>>>({ method: 'PUT' })

  function updatePerson(id: PersonId, data: PersonOutput): Promise<PersonOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updatePerson,
  }
}

function useDeletePerson(): UseDeletePerson {
  const axios = useAxios<PromiseType<ReturnType<UseDeletePerson['deletePerson']>>>({ method: 'DELETE' })

  function deletePerson(id: PersonId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deletePerson,
  }
}

function useExportPersons(): UseExportPersons {
  const axios = useAxios<void>({ method: 'GET' })

  async function exportPersons(filters?: OptionalFiltersParam): Promise<void> {
    await axios.exec({ url: `/${VERSION}/${RESOURCE}/export`, params: filters })
  }

  return {
    ...axios,
    exportPersons,
  }
}

export {
  useGetPersons,
  useGetPersonsBasic,
  useGetPerson,
  useGetPersonsDashboardEntries,
  useGetPersonsDashboardRetirements,
  useGetPersonsDashboardLeasing,
  useGetPersonBudget,
  useCreatePerson,
  useUpdatePerson,
  useDeletePerson,
  useExportPersons,
}
