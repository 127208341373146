import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import {
  ProfUnitOutput,
  ProfUnitId,
  ProfUnitInput,
  UseGetProfUnits,
  UseGetProfUnitsBasic,
  UseCreateProfUnit,
  UseUpdateProfUnit,
  UseDeleteProfUnit,
} from '@/api/types/profUnit'
import { PromiseType } from '@/utils/types/PromiseType'
import { BasicEntity } from '@/api/types/misc'

const VERSION = 'v1'
const RESOURCE = 'professional-units'

function useGetProfUnits<T>(): UseGetProfUnits<T> {
  const axios = useAxiosPaginated<T>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetProfUnitsBasic(): UseGetProfUnitsBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useCreateProfUnit(): UseCreateProfUnit {
  const axios = useAxios<PromiseType<ReturnType<UseCreateProfUnit['createProfUnit']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createProfUnit(data: ProfUnitInput): Promise<ProfUnitOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createProfUnit,
  }
}

function useUpdateProfUnit(): UseUpdateProfUnit {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateProfUnit['updateProfUnit']>>>({ method: 'PUT' })

  function updateProfUnit(id: ProfUnitId, data: ProfUnitOutput): Promise<ProfUnitOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateProfUnit,
  }
}

function useDeleteProfUnit(): UseDeleteProfUnit {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteProfUnit['deleteProfUnit']>>>({ method: 'DELETE' })

  function deleteProfUnit(id: ProfUnitId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteProfUnit,
  }
}

export { useGetProfUnits, useGetProfUnitsBasic, useCreateProfUnit, useUpdateProfUnit, useDeleteProfUnit }
