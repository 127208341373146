import { BasicEntity } from '@/api/types/misc'
import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'
import { PromiseType } from '@/utils/types/PromiseType'

import {
  ProfileInput,
  ProfileOutput,
  ProfileId,
  UseGetProfiles,
  UseGetProfilesBasic,
  UseCreateProfile,
  UseDeleteProfile,
  UseUpdateProfile,
} from './types/profile'

const VERSION = 'v1'
const RESOURCE = 'profiles'

function useGetProfiles(): UseGetProfiles {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetProfiles['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetProfilesBasic(): UseGetProfilesBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useCreateProfile(): UseCreateProfile {
  const axios = useAxios<PromiseType<ReturnType<UseCreateProfile['createProfile']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createProfile(data: ProfileInput): Promise<ProfileOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createProfile,
  }
}

function useUpdateProfile(): UseUpdateProfile {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateProfile['updateProfile']>>>({ method: 'PUT' })

  function updateProfile(id: ProfileId, data: ProfileOutput): Promise<ProfileOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateProfile,
  }
}

function useDeleteProfile(): UseDeleteProfile {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteProfile['deleteProfile']>>>({ method: 'DELETE' })

  function deleteProfile(id: ProfileId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteProfile,
  }
}

export { useGetProfiles, useGetProfilesBasic, useCreateProfile, useUpdateProfile, useDeleteProfile }
